<template>
  <vCard header="USP (Unique selling proposition)" col="4">
    <formUsp />
  </vCard>
</template>


<script>
export default {
  components: {
    formUsp: () => import("@/components/forms/usp/formUsp")
  }
}
</script>